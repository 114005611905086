@import 'Variables';

.miconfiguracion_container {
    height: 125vh;
    width: 100%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;

    h3 {
        color: $verde-lima !important;
        font-size: 50px !important;
        font-weight: 900;
    }

    h4 {
        color: $gris_claro;
        font-size: 35px !important;

        span {
            color: $gris_oscuro
        }
    }

    p {
        color: $gris_oscuro !important;
    }
}

.btn_container_config {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input_config {
    width: 600px;
    height: 45px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding-left: 15px;
}

.button_config {
    margin-top: 30px;
    width: 600px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    cursor: pointer;
    color: $blanco;
}

.button_config:hover {
    animation: scale 1s infinite;
    transition: 0.2s ease-in-out;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (min-width: 1500px) {
    .miconfiguracion_container {
        height: calc(100vh - 120px) !important;
    }

}

@media (max-width: 768px) {

    .miconfiguracion_container {
        height: auto !important;
        padding-top: 50px;
        padding-bottom: 50px;
        h3{
            font-size: 35px !important;
        }
        h4{
            font-size: 25px !important;
        }
    }

    .button_config {
        width: 300px;
    }
}