* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.contacto_container {
  height: 140vh !important;
  width: 100%;
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  padding-top: 120px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.container-contacto-lottie {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.form_contacto {
  padding: 20px;
  box-shadow: 0 0 10px #000000;
}

.contacto_amddii {
  width: 40%;
}

.contacto-desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contacto-desc img {
  height: 88px;
  width: 272px;
}
.contacto-desc h4 {
  font-weight: 800;
  font-size: 50px !important;
}
.contacto-desc h4 span {
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contacto-desc h3 {
  text-align: justify !important;
  color: #000000 !important;
}

.portada_contacto {
  height: 40vh;
  width: 100%;
  background: #00d799;
  background-image: url(../images/contacto.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portada_contacto_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}
.portada_contacto_container img {
  width: 100vw;
}

.portada_contacto_titulo h3 {
  font-size: 60px !important;
  font-weight: 900 !important;
  margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important;
}
.portada_contacto_titulo h3 span {
  color: #00d799;
}

.cont {
  padding-left: 10px;
  font-size: 15px;
}

.titulo {
  padding-bottom: 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

.cont-text {
  text-align: justify;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif !important;
}

.container-contacto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-contact {
  background: linear-gradient(to right, #b47a1a, #ffe55a) !important;
  color: #ffffff !important;
}

.button-contact:hover {
  animation: scale 1s infinite;
  transition: 0.2s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 768px) {
  lottie-player {
    display: none;
  }
  .contacto_container {
    width: 100% !important;
    height: 100% !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-contacto {
    margin-top: 16px;
    width: 100%;
    height: 100%;
  }
  .container-contacto .d-flex {
    flex-direction: column;
  }
  .input_card {
    margin-top: 16px;
  }
  textarea {
    width: 310px !important;
  }
  input {
    width: 310px !important;
  }
  select {
    width: 310px !important;
  }
  .referencias_card {
    width: 320px !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .input_container {
    width: 310px !important;
  }
  .ref_card {
    flex-direction: row !important;
    align-items: center;
    margin-top: 10px;
  }
  .cont {
    margin-bottom: 0;
  }
  .input_contact_container {
    width: 310px !important;
  }
  .ingresos_description {
    width: 340px;
  }
  .contacto_amddii {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .container-contacto-lottie {
    height: 120vh;
    flex-direction: column;
    margin-top: 50px;
  }
  .contacto-desc {
    width: 90%;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .contacto-desc h4 {
    font-size: 35px !important;
  }
  .contacto-desc h3 {
    color: #000000 !important;
  }
  .portada_contacto_titulo h3 {
    font-size: 38px !important;
  }
}
@media (min-width: 1171px) and (max-width: 1480px) {
  .contacto_container {
    width: 100% !important;
  }
  .contacto-desc h4 {
    font-size: 35px !important;
  }
  .contacto-desc h3 {
    color: #000000 !important;
  }
}
@media (min-width: 769px) and (max-width: 1170px) {
  .contacto_container {
    width: 100% !important;
    height: 100% !important;
  }
  .container-contacto-lottie {
    flex-direction: column;
    height: 120vh;
  }
  .contacto-desc {
    justify-content: center;
    align-items: center;
  }
  .contacto-desc h4 {
    font-size: 35px !important;
  }
  .contacto-desc h3 {
    color: #000000 !important;
  }
  .contacto_amddii {
    width: 500px;
  }
}
@media (max-width: 482px) {
  .contacto-desc {
    justify-content: center;
    align-items: center;
  }
  .contacto-desc h4 {
    font-size: 28px !important;
  }
  .contacto-desc h3 {
    color: #000000 !important;
    font-size: 17px !important;
  }
}/*# sourceMappingURL=Contacto.css.map */