$verde-lima: #00d799;
$verde-agua: #7febcc;
$verde-menta: #4beccd;
$gris-claro: #b4b4b4;
$gris-oscuro: #666666;
$blanco: #ffffff;
$negro: #000000;
$transition: 0.5s;

* {
    font-family: 'Poppins', sans-serif !important;
}

body {
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 0 !important;

}

h1 {
    font-size: 30px;
    width: 140px !important;
    color: $blanco !important;
    display: inline-block !important;
    margin: 0 0 0 0 !important;
    height: 40px !important;
    font-weight: 900 !important;
    display: inline;
    text-align: justify !important;
}

h2 {
    color: $blanco !important;
}

h3 {
    text-align: center !important;
    color: $blanco !important;
    font-size: 20px !important;
}

h4 {
    font-size: 20px !important;
    text-align: justify !important;
    display: inline;
    margin: 0 !important;
    padding-bottom: 10px !important;

}

h5 {
    font-size: 15px !important;
    
}

p {
    color: $blanco !important;
}

a,
ul,
li {
    text-decoration: none !important;
}

input {
    // box-shadow: 0 0 3px 3px rgb(0,0 ,0,0.8);
    color: $gris-oscuro !important;
    border-color: $verde-lima !important;
    border: 3px solid $verde-lima !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


input::placeholder {
    color: $gris-oscuro !important;
    opacity: 1 !important;
}

textarea {
    color: $gris-oscuro !important;
    border-color: $verde-lima !important;
    border: 3px solid $verde-lima !important;
}

textarea::placeholder {
    color: $gris-oscuro !important;
}

select {
    color: $gris-oscuro !important;
    border-color: $verde-lima !important;
    border: 3px solid $verde-lima !important;
}

#root {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

section {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.button-effect {
    color: $blanco ;
    background-color: $verde-lima !important;
    padding: 10px 10px 10px 10px ;
    margin: 0 20px 40px 20px ;
}

.button-effect:hover {
    animation: scale 1s infinite;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.whatsapp-icon {
    z-index: 2;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.whatsapp-icon:hover {
    animation: scale 1s infinite;
}

.zoom {
    transition: 0.8s ease-in-out;
}

.zoom:hover {
    transform: scale(1.01);
}

// @keyframes zoom {
//     0% {
//         transform: scale(1);
//     }

//     50% {
//         transform: scale(1.05);
//     }

//     100% {
//         transform: scale(1);
//     }
// }

@media (max-width: 768px){
    // .whatsapp-icon{
    //     display: none;
    // }
}