@import 'Variables';
/* Importamos las variables */

.header_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../images/header.png);
    background-size: 100% 120px;
    background-repeat: repeat;
    height: 120px;
    font-size: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 4px 0 $verde-lima;
}

.navbar {
    width: 100%;
}

.navbar-nav {
    width: 90%;
    justify-content: space-around;
}

.header_cont_item {
    width: 100%;
    display: flex;
    justify-content: space-around !important;
}

.contenedor_logo_menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo_header_container {
    height: 60px;
}

.disable-bootstrap-animations * {
    transition: none !important;
    animation: none !important;

}

a {
    color: $blanco !important;
}

.bg-gris {
    background: linear-gradient(to right, #b47a1a, #ffe55a) !important; 

    :hover {
        background-color: $gris-oscuro !important;
    }
}

.nav-item {
    padding: 0 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-link {
    transition: all $transition ease-in-out !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $gris-oscuro !important;
}

.nav-link:hover {
    span {
        background: linear-gradient(to right, #b47a1a, #ffe55a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    transform: scale(1.1) !important;
    transition: all $transition ease-in-out !important;
}

.nav-link.dropdown-toggle:hover:after {
    border-top-color:  #ffe55a;
}

.dropdown-menu-redaccion {
    display: none;
    /* Puedes agregar estilos adicionales aquí, como position: absolute, right: 0, etc. */
    /* Ajustamos el estilo para mostrar el menú a la derecha */
    position: absolute;
    right: 0;
    top: 0;
    left: 100%;
    width: 300px;
    background: linear-gradient(to right, #b47a1a, #ffe55a) 
}

/* Mostrar el menú al hacer hover en el elemento padre */
.li-redacciongeneral:hover .dropdown-menu-redaccion {
    display: block;
}

/* Mostrar el menú al hacer clic en el elemento padre */
// .li-redacciongeneral:focus .dropdown-menu-redaccion {
//     display: block;
// }

.dropdown-menu-redaccion ul li {
    text-decoration: none !important;
}

.li-redaccion-title {
    height: 32px !important;
    display: flex;
    align-items: center;
}

.li-redacciongeneral {
    height: 32px !important;
    display: flex;

    align-items: center;
}

.li-redaccion {
    text-decoration: none !important;
    height: 32px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.li-redacciongeneral {
    padding-left: 16px;

    ul {
        text-decoration: none !important;
        padding-left: 0 !important;
        border-radius: 5px;
    }
}

.dropdown-menu-redaccion li {
    list-style-type: none;
    height: 32px !important;

}

.dropdown-menu-redaccion:hover {
    background:linear-gradient(to right, #b47a1a, #ffe55a) !important;
}

.dropdown-menu-redaccion {
    display: none;
}

.hidden {
    display: none !important;
}

.btn_header {
    margin-left: 10px;
    border-radius: 5px !important;
    background: linear-gradient(to right, #b47a1a, #ffe55a) !important;
    color: $blanco !important;
}

@media (max-width: 768px) {

    .header_container {
        max-width: 100%;
        max-height: 100%;
    }

    .img_header {
        height: 75px;
    }

    .contenedor_logo_menu {
        width: 100% !important;
        flex-direction: row !important;

        justify-content: space-between !important;
        padding: 0 0 0 0 !important;
    }

    .navbar_header_container {
        width: 95% !important;
    }

    .menu-closed {
        display: none;
    }

    .menu-open {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        top: 0;
        left: 0;
        background-image: url(../images/fondo_blanco.jpeg);
        background-size: 500px 500px;
        background-repeat: repeat;
        justify-content: center;
        align-items: center;

    }

    .dropdown-menu {
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
    }

    .dropdown {
        display: flex;
        flex-direction: column;

    }

    .dropdown-item {
        min-height: 0;
    }

    .nav-link {
        color: $blanco !important;
    }

    .li-redacciongeneral {
        display: flex;
        align-items: flex-start !important;
        display: none !important;
    }

    .navbar-nav {
        width: 100%;
        height: 100%;
        // margin-top: 120px;
    }

    .dropdown-menu-end {
        width: 370px
    }

    .dropdown-menu-redaccion {
        display: none;
        /* Puedes agregar estilos adicionales aquí, como position: absolute, right: 0, etc. */
        /* Ajustamos el estilo para mostrar el menú a la derecha */
        position: absolute;
        left: 0;
        bottom: 0;
        width: 370px;
        height: 96px;
        background: $verde-lima !important;
        z-index: 2;
    }

    .li-redacciongeneral.open .dropdown-menu-redaccion {
        display: block !important;
    }

    .hidden {
        display: block !important;
    }

    .nav-link {

        font-weight: 600 !important;
        font-size: 15px !important;
    }

    .btn_header{
        width: 200px;
    }   
}

@media (min-width: 768px) and (max-width:1290px) {

    .nav-link {
        color: $gris-oscuro;
        font-weight: 600 !important;
        font-size: 15px !important;
    }

    .img_header {
        height: 80px;
    }

    .menu-open {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        top: 0;
        left: 0;
        background-image: url(../images/header.png);
        background-size: 500px 500px;
        background-repeat: repeat;
        justify-content: center;
        align-items: center;

    }


    .li-redacciongeneral {
        display: flex;
        align-items: flex-start !important;
        display: none !important;
    }

    .dropdown-menu-redaccion {
        display: none;
        /* Puedes agregar estilos adicionales aquí, como position: absolute, right: 0, etc. */
        /* Ajustamos el estilo para mostrar el menú a la derecha */
        position: absolute;
        left: 0;
        bottom: 0;
        width: 370px;
        height: 96px;
        background: $verde-lima !important;
        z-index: 2;
    }

    .hidden {
        display: block !important;
    }


}

@media (max-width: 991px) {
    .nav-link {
        color: $gris-oscuro !important;
    }
}