@import 'Variables';

.ingresos_container {
    height: 100vh;
    width: 100%;
    padding-top: 125px;
    display: flex;
    flex-direction: column;
}


.ingresos_description {
    width: 50% !important;
    height: 87%;
    // padding: 20px 40px 20px 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: 0 0 15px $negro;
    p {
        font-size: 18px;
        text-align: justify;

    }
}

.container-contacto-empresa{
    width: 70%;
    .titulo{
        color: $verde-lima !important;
        font-size: 30px !important;
        padding-bottom: 0 !important;
    }
    h4{
        text-align: center !important;
        font-size: 15px !important;
    }
}

.emprendimiento{
    height: 82%;
}

.investigacion{
    height: 80%;
}

.puestos_list{
    margin: 5px 0 5px 0;
}

.form_ingreso{
    // margin-top: 35px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-programa{
    max-height: 100%;
    height: auto;
    float: left; /* Alinea la imagen a la izquierda */
    margin-right: 20px; /* Espacio entre la imagen y el texto */
}

.ingresos_card {
    min-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // background-image: url(../images/Programa/programa50.png);
    // background-size: auto 100%; 
    // background-repeat: no-repeat;
    // background-position: top left;
    padding: 0 !important;
    margin:  0 !important;
    // object-fit: fill;
}

.programa-formulario {
    // margin-top: 20px;
    padding-left: 0 !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

.seleccionar-archivo {
    background: $verde-lima;
    border-radius: 5px;
}

.custom-file-input.is-valid ~ .custom-file-label::after {
    content: "Archivo no seleccionado";
    color: red; /* Cambia el color a tu preferencia */
}

.centered-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

@supports (-webkit-appearance: none) {
    .ingresos_container {
        min-height: 125vh;
        padding-top: 100px;

    }
    .ingresos_description{
        min-width: 75%;
        min-height: 84%;
    }
    .emprendimiento{
        min-height: 75%;
    }
    .investigacion{
        min-height: 76%;
    }
}

@media (max-width: 768px){
    .ingresos_container{
        padding-top: 140px;
    }

    .ingresos_description{
        flex-direction: column;
        // padding: 0 30px 0 30px;
        height: 100%;
        width: 95% !important;
        padding-bottom: 20px;
    }
    .ingresos_card{
        padding-right: 0;
    }
    .ingresos_container{
        height: 100% !important;
        margin-bottom: 20px;
    }
    .container-contacto{
        padding-left: 10px;
    }
    .input_celular{
        margin-top: 20px;
    }

    .form_ingreso {
        margin-top: 20px;
        height: auto;
    }



    @supports (-webkit-appearance: none){
        .ingresos_description{
            width: 95% !important;
        }
        .ingresos_card,
        .emprendimiento_card,
        .investigacion_card{
            width: 100%;
            img{
                width: 100%;
                height: auto;
            }
        }

        .form_ingreso{
            width: 100%;
        }
    }
}

@media (min-width: 1600px){
    .ingresos_description{
        width: 70%;
        height: 50%;
    }
    @supports (-webkit-appearance: none){
        .ingresos_container {
            min-height: 100vh;
            padding-top: 100px;
    
        }
        .investigacion{
            min-width: 50% ;
            min-height: 50% ;
        }
        .emprendimiento{
            min-width: 50% ;
            min-height: 50% ;
        }
        .ingresos{
            min-width: 52% ;
            min-height: 52% ;
        }
        .ingresos_description{
            min-height: 90%;
            min-width: 70%;
        }

        .input1{
            height: 40px !important;
        }
        
    }


}

// @media (max-width: 910px){

//     .ingresos_container{
//         height: 200vh !important;

//     }
//     .ingresos_description{
//         flex-direction: column;
//     }
//     .ingresos_card{
//         width: 100%;
//         // height: 800px;
//     }
//     .form_ingreso{
        
//         width: 100%;
//         margin-bottom: 40px;
//     }
// }

@media (min-width: 1190px) and (max-width: 1480px){
    .ingresos_card{
        padding-top: 0;
        h2{
            font-size: 25px;
            padding-bottom: 0 !important;
            margin-bottom: 10px !important;
        }
        p{
            font-size: 16px !important;
        }
    }
    
    .ingresos_description{
        width: 95% !important;
    }
}

@media (min-width: 650px) and (max-width: 1189px){
    .ingresos_container{
        width: 789px;
        height: 230vh;
    }
    .ingresos_description {
        flex-direction: column;
    }

    @supports (-webkit-appearance: none){
        .ingresos_description{
            width: 626px !important;
        }
        .ingresos_card,
        .emprendimiento_card,
        .investigacion_card
        {
            width: 100%;
        }

        .form_ingreso{
            width: 100%;
        }
    }
}