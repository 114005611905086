@charset "UTF-8";
* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.login_container {
  width: 600px;
  height: 100vh;
}

.titulo_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.titulo_login h2 {
  color: #00d799 !important;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 5px !important;
}

.linea {
  flex-grow: 1;
  height: 5px;
  width: 50px;
  /* Grosor de la línea */
  background-color: #00d799;
  /* Color de la línea */
}

.login_opciones {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.login_opciones a {
  color: #00d799 !important;
  font-size: 14px;
}

/* a {
    font-size: 14px !important;
    color: $verde-lima !important;
} */
.form_login_user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form_login_user input {
  width: 100%;
}

.form_login_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button_logearse {
  margin-top: 30px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  cursor: pointer;
}

a.login_preguntas:hover {
  text-decoration: underline !important;
  /* Agrega subrayado al hacer hover */
}

.button_logearse:hover {
  animation: scale 1s infinite;
  transition: 0.2s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 768px) {
  .login_opciones {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button_logearse {
    width: 310px;
  }
}/*# sourceMappingURL=Login.css.map */