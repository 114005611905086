@charset "UTF-8";
* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.registro_container {
  width: 600px;
  height: 120vh;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Estilos para el formulario */
.form_registro_container {
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Estilos para los campos de entrada */
.input_registro {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Estilos para el botón de enviar */
.form_registro button[type=submit] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form_registro button[type=submit]:hover {
  background-color: #0056b3;
}

.secciones_registro_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.bg_green {
  background: #00d799 !important;
}

.linea_green {
  background-color: #00d799 !important;
}

/*  */
.linea_entre_circulos {
  flex: 1;
  height: 5px; /* Grosor de la línea */
  background-color: #b4b4b4;
}

/*  */
.secciones_registro {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #b4b4b4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.titulo_registro {
  color: #00d799 !important;
  font-weight: 900;
  margin-bottom: 20px;
}

.form_registro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button_logearse {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.input_registro_codigo {
  width: 50px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.input_especialidad {
  margin-bottom: 20px;
}

.form_registro_codigo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.form_registro_codigo h3 {
  font-size: 15px !important;
  color: #00d799 !important;
}

.input_card {
  width: 100%;
}

.codigo_partes {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
}

.input_codigo {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 20px;
  text-align: center !important;
}

.pais_select {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .secciones_registro_container {
    width: 310px;
  }
  .codigo_partes {
    width: 310px;
  }
  .input_codigo {
    width: 50px !important;
    height: 50px;
  }
  .form_registro_codigo {
    width: 310px;
  }
}/*# sourceMappingURL=Registro.css.map */