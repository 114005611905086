@import 'Variables';

.subheader_container {
    position: absolute;
    top: 0.5%;
    right: 1.5%;
    z-index: 155555;
    position: fixed;
    // display: none !important;

}



.subheader-content {
    width: 250px;
    margin: 0 ;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.login-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.inicio{
    margin-left: 5px;
}

.zoom-header {
    transition: 0.8s ease-in-out;
    color: $verde-lima !important;
    font-size: 14px !important;
    h6 {
        font-size: 15px !important;
        font-weight: 900 !important;
        margin: 5px 0 0 0 !important;
        padding: 0 0 0 0 !important;
        border-radius: 10px;

        span {
            background: linear-gradient(to right, #b47a1a, #ffe55a);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.btn_bg_login{
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    border: none !important;
}

.miinf_login{
    color: $negro !important;
}

.zoom-header:hover {
    transform: scale(1.2);
}

// Media queries

@media (max-width: 768px) {

    .header-content {
        flex-direction: column;
    }

    .login-btn {
        margin-top: 1rem;
    }

    .subheader_container {
        right: inherit;
    }

}

