@import 'Variables';

.contacto_container {
    height: 140vh !important;
    width: 100%;
    color: $blanco !important;
    font-family: 'Poppins', sans-serif !important;
    padding-top: 120px !important;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;

}

.container-contacto-lottie {
    // margin-top: 100px;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100vh;

}

.form_contacto {
    padding: 20px;
    box-shadow: 0 0 10px $negro;
}

.contacto_amddii {
    width: 40%;
}

.contacto-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // align-items: center;
    img {
        height: 88px;
        width: 272px;
    }

    h4 {

        font-weight: 800;
        font-size: 50px !important;

        span {

            background: linear-gradient(to right, #b47a1a, #ffe55a);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    h3 {
        text-align: justify !important;
        color: $negro !important;
    }
}


.portada_contacto {

    height: 40vh;
    width: 100%;
    background: $verde-lima;
    background-image: url(../images/contacto.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portada_contacto_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;

    img {
        width: 100vw;
    }
}

.portada_contacto_titulo {

    // position: absolute;
    h3 {
        font-size: 60px !important;
        font-weight: 900 !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;

        span {
            color: $verde-lima;
        }
    }
}


.cont {
    padding-left: 10px;
    font-size: 15px;
}

.titulo {
    padding-bottom: 20px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
}



.cont-text {
    text-align: justify;
    padding-bottom: 20px;
    font-family: 'Poppins', sans-serif !important;
}

.container-contacto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-contact {
    // border: 2px solid $blanco !important;
    background: linear-gradient(to right, #b47a1a, #ffe55a) !important;
    color: $blanco !important;
}

.button-contact:hover {
    animation: scale 1s infinite;
    transition: 0.2s ease-in-out;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    lottie-player {
        display: none;
    }

    .contacto_container {
        width: 100% !important;
        height: 100% !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }



    .container-contacto {
        margin-top: 16px;
        width: 100%;
        height: 100%;

        .d-flex {
            flex-direction: column;
        }
    }

    .input_card {
        margin-top: 16px;
    }

    textarea {
        width: 310px !important;
    }

    input {
        width: 310px !important;
    }

    select {
        width: 310px !important;
    }

    .referencias_card {
        width: 320px !important;
        flex-direction: column !important;
        justify-content: center !important;
    }

    .input_container {
        width: 310px !important;
    }

    .ref_card {
        flex-direction: row !important;
        align-items: center;
        margin-top: 10px;
    }

    .cont {
        margin-bottom: 0;
    }

    .input_contact_container {
        width: 310px !important;
    }

    .ingresos_description {
        width: 340px;
    }

    .contacto_amddii{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .container-contacto-lottie {
        height: 120vh;
        flex-direction: column;
        margin-top: 50px;
    }

    .contacto-desc {
        width: 90%;
        justify-content: center !important;
        align-items: center !important ;
        margin-right: 0;
        margin-bottom: 20px;
        h4 {
            font-size: 35px !important;
        }

        h3 {
            color: $negro !important;
        }
    }

    .portada_contacto_titulo{
        h3{
            font-size: 38px !important;
        }
    }

}


@media (min-width: 1171px) and (max-width: 1480px) {
    .contacto_container {
        width: 100% !important;

    }

    .contacto-desc {

        h4 {
            font-size: 35px !important;
        }

        h3 {
            color: $negro !important;
        }
    }

}

@media (min-width: 769px) and (max-width: 1170px) {
    .contacto_container {
        width: 100% !important;
        height: 100% !important;
    }

    .container-contacto-lottie {
        flex-direction: column;
        height: 120vh;
    }
    .contacto-desc {

        justify-content: center;
        align-items: center;
        h4 {
            font-size: 35px !important;
        }

        h3 {
            color: $negro !important;
        }
    }

    .contacto_amddii{

        width: 500px;
    }
}

@media (max-width: 482px) {


    .contacto-desc {

        justify-content: center;
        align-items: center;
        h4 {
            font-size: 28px !important;
        }

        h3 {
            color: $negro !important;
            font-size: 17px !important;
        }
    }
}