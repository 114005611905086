@import 'Variables';

.login_container {
    width: 600px;
    height: 100vh;
}

.titulo_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
margin-bottom: 20px;
    h2 {

        color: $verde-lima !important;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 5px !important;
    }
}

.linea {
    flex-grow: 1;
    height: 5px;
    width: 50px;
    /* Grosor de la línea */
    background-color: $verde-lima;
    /* Color de la línea */
}


.login_opciones {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a{
        color: $verde-lima !important;
        font-size: 14px;
    }
}

/* a {
    font-size: 14px !important;
    color: $verde-lima !important;
} */

.form_login_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    input {
        width: 100%;
    }
}

.form_login_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_logearse {
    margin-top: 30px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    cursor: pointer;
}

a.login_preguntas:hover {
    text-decoration: underline !important;
    /* Agrega subrayado al hacer hover */
}

.button_logearse:hover {
    animation: scale 1s infinite;
    transition: 0.2s ease-in-out;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (max-width: 768px){
    .login_opciones{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .button_logearse{
        width: 310px;
    }
}