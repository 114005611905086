* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.miconfiguracion_container {
  height: 125vh;
  width: 100%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}
.miconfiguracion_container h3 {
  color: #00d799 !important;
  font-size: 50px !important;
  font-weight: 900;
}
.miconfiguracion_container h4 {
  color: #b4b4b4;
  font-size: 35px !important;
}
.miconfiguracion_container h4 span {
  color: #666666;
}
.miconfiguracion_container p {
  color: #666666 !important;
}

.btn_container_config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_config {
  width: 600px;
  height: 45px;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-left: 15px;
}

.button_config {
  margin-top: 30px;
  width: 600px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  cursor: pointer;
  color: #ffffff;
}

.button_config:hover {
  animation: scale 1s infinite;
  transition: 0.2s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@media (min-width: 1500px) {
  .miconfiguracion_container {
    height: calc(100vh - 120px) !important;
  }
}
@media (max-width: 768px) {
  .miconfiguracion_container {
    height: auto !important;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .miconfiguracion_container h3 {
    font-size: 35px !important;
  }
  .miconfiguracion_container h4 {
    font-size: 25px !important;
  }
  .button_config {
    width: 300px;
  }
}/*# sourceMappingURL=Miconfiguracion.css.map */