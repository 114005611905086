* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.ingresos_container {
  height: 100vh;
  width: 100%;
  padding-top: 125px;
  display: flex;
  flex-direction: column;
}

.ingresos_description {
  width: 50% !important;
  height: 87%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0 0 15px #000000;
}
.ingresos_description p {
  font-size: 18px;
  text-align: justify;
}

.container-contacto-empresa {
  width: 70%;
}
.container-contacto-empresa .titulo {
  color: #00d799 !important;
  font-size: 30px !important;
  padding-bottom: 0 !important;
}
.container-contacto-empresa h4 {
  text-align: center !important;
  font-size: 15px !important;
}

.emprendimiento {
  height: 82%;
}

.investigacion {
  height: 80%;
}

.puestos_list {
  margin: 5px 0 5px 0;
}

.form_ingreso {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-programa {
  max-height: 100%;
  height: auto;
  float: left; /* Alinea la imagen a la izquierda */
  margin-right: 20px; /* Espacio entre la imagen y el texto */
}

.ingresos_card {
  min-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 !important;
  margin: 0 !important;
}

.programa-formulario {
  padding-left: 0 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.seleccionar-archivo {
  background: #00d799;
  border-radius: 5px;
}

.custom-file-input.is-valid ~ .custom-file-label::after {
  content: "Archivo no seleccionado";
  color: red; /* Cambia el color a tu preferencia */
}

.centered-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@supports (-webkit-appearance: none) {
  .ingresos_container {
    min-height: 125vh;
    padding-top: 100px;
  }
  .ingresos_description {
    min-width: 75%;
    min-height: 84%;
  }
  .emprendimiento {
    min-height: 75%;
  }
  .investigacion {
    min-height: 76%;
  }
}
@media (max-width: 768px) {
  .ingresos_container {
    padding-top: 140px;
  }
  .ingresos_description {
    flex-direction: column;
    height: 100%;
    width: 95% !important;
    padding-bottom: 20px;
  }
  .ingresos_card {
    padding-right: 0;
  }
  .ingresos_container {
    height: 100% !important;
    margin-bottom: 20px;
  }
  .container-contacto {
    padding-left: 10px;
  }
  .input_celular {
    margin-top: 20px;
  }
  .form_ingreso {
    margin-top: 20px;
    height: auto;
  }
  @supports (-webkit-appearance: none) {
    .ingresos_description {
      width: 95% !important;
    }
    .ingresos_card,
    .emprendimiento_card,
    .investigacion_card {
      width: 100%;
    }
    .ingresos_card img,
    .emprendimiento_card img,
    .investigacion_card img {
      width: 100%;
      height: auto;
    }
    .form_ingreso {
      width: 100%;
    }
  }
}
@media (min-width: 1600px) {
  .ingresos_description {
    width: 70%;
    height: 50%;
  }
  @supports (-webkit-appearance: none) {
    .ingresos_container {
      min-height: 100vh;
      padding-top: 100px;
    }
    .investigacion {
      min-width: 50%;
      min-height: 50%;
    }
    .emprendimiento {
      min-width: 50%;
      min-height: 50%;
    }
    .ingresos {
      min-width: 52%;
      min-height: 52%;
    }
    .ingresos_description {
      min-height: 90%;
      min-width: 70%;
    }
    .input1 {
      height: 40px !important;
    }
  }
}
@media (min-width: 1190px) and (max-width: 1480px) {
  .ingresos_card {
    padding-top: 0;
  }
  .ingresos_card h2 {
    font-size: 25px;
    padding-bottom: 0 !important;
    margin-bottom: 10px !important;
  }
  .ingresos_card p {
    font-size: 16px !important;
  }
  .ingresos_description {
    width: 95% !important;
  }
}
@media (min-width: 650px) and (max-width: 1189px) {
  .ingresos_container {
    width: 789px;
    height: 230vh;
  }
  .ingresos_description {
    flex-direction: column;
  }
  @supports (-webkit-appearance: none) {
    .ingresos_description {
      width: 626px !important;
    }
    .ingresos_card,
    .emprendimiento_card,
    .investigacion_card {
      width: 100%;
    }
    .form_ingreso {
      width: 100%;
    }
  }
}/*# sourceMappingURL=Ingresos.css.map */