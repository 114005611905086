@import 'Variables';

.footer-description {
    height: 580px;
    width: 1481px;
    background-image: url(../images/Footer/fondo_footer.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: $blanco;
    // box-shadow: inset 0px 10px 80px 0 rgb(0, 0, 0, 0.8);
    padding-top: 20px;
}

.footer-description-amddi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    h4{
        font-weight: 900 !important;
        font-size: 20px !important;
    }

}

.footer-content {
    margin-top: 20px;
    padding: 0 30px 0 30px;
    text-align: justify;
    width: 95%;
    h5{
        font-size: 17px !important;
        font-weight: 300 !important;
        color: $blanco;
    }

}

.footer-socialnetwork {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
    a{
        img{
            margin-left: 5px;
        }
    }
}

.svg-effect {

    transition: filter 0.3s;
}

.svg-effect:hover {

    animation: scale 1s infinite;
}

.li-effect:hover {
    animation: scale 1s infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.margin_title {
    margin-top: 20px !important;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(1);
    }
}


.footer-description-ubicanos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 65%;
    margin-bottom: 40px;
    h4{
        font-weight: 500 !important;
        font-size: 20px !important;

        color: $blanco;
    }
}


.footer-description-llamanos {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 400px;
    padding-top: 30px;
}

.llamanos_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.horario_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// .footer-description-redes {
//     padding-top: 30px;
// }

.card-ictel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card-icloc {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    h5{
        font-size: 16px !important;
        font-weight: 800 !important;
        color: $blanco;
    }
}

.card-localizacion {
    padding-left: 10px;
    display: flex !important;
    align-items: center;
    width: 100%;
}

.card-localizacion > h5:first-of-type {
    font-weight: 800 !important;
    color: $blanco !important;
    margin-bottom: 0;
}

.card-localizacion > h5:last-of-type {
    font-weight: 400 !important;
    margin-bottom: 0;
}

.bi-geo-alt {
    padding-top: 5px !important;
}

.container-ictel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    h5{
        font-weight: 400 !important;
        color: $blanco !important;
        margin-bottom: 0;  
    }
}

.footer-text {
    padding-top: 15px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    color: $gris-oscuro !important;
}


.footer-description-pago {
    padding-top: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.pago-container img {
    padding-left: 5px;
}

.libro-container {
    padding-bottom: 10px;
}

.container-list {
    width: 100%;
    margin-bottom: 0 !important;
    list-style: none !important;
    text-decoration: none;

    li {
        list-style: none !important;
        margin-bottom: 5px !important;

        h5 {
            color: $verde-lima !important;
        }
    }
}

.servicios {
    text-decoration: none !important;
}

.servicios_l {
    color: $verde-lima !important;
    font-size: 17px !important;
    font-weight: 700 !important;
}

.footer_contacto{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.footer_contacto{
    display: flex;
    align-items: center;
}

.title_ubi{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 768px) {
    .footer_container {
        width: 100%;
    }

    .footer-description {
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }


    .footer-text {
        font-size: 12px;
    }

    .container-list {
        width: 90%;

    }
    .footer-description-amddi{
        width: 90%;
    }

    .footer-socialnetwork{
        justify-content: space-around;
    }

    .footer-description-ubicanos{
        justify-content: center;
        width: 90%;
        h4{
            text-align: center;
            margin-right: 0 !important;
        }
    }
    .container-list{
        padding-left: 0;
        ul{
            padding-left: 0;
        }
    }
    .footer-content{
        padding: 0 0 0 0;
    }

    .footer_contacto{
        flex-direction: column;
    }

    .card-localizacion{
        flex-direction: column;
        h5{
            text-align: center;
        }
    }

    .card-icloc{
        svg{
            display: none;
        }
    }

    .img_tesis{
        height: 150px;
    }

    .pago-container{
        img{
            height: 30px;
        }
    }

    .card-icloc{
        margin-bottom: 5px;
    }
}


@media (min-width: 1500px) {

    .footer_container {
        width: 100% !important;
        font-weight: 600;
    }

    .footer-description {
        width: 100%;
        height: 700px;
    }

    .footer-content {
        // width: 500px;

        h5 {
            font-size: 20px !important;

        }
    }

    .footer-description-amddi {
        // width: 500px;

        h4 {
            font-size: 30px !important;
        }
    }

    .container-ictel {
        h5 {
            font-size: 20px !important;
        }
    }

    .footer-description-llamanos {
        width: 100%;
    }

    .card-ictel {
        svg {
            width: 30px !important;
            height: 30px !important;
        }
    }

    .footer-description-pago {
        margin-top: 5px;
        width: 100%;
    }

    .svg-effect {
        height: 50px;
    }

    .footer-description-redes {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pago-container {
        img {
            height: 35px;
        }
    }



    .pro_list {
        color: $verde-lima !important;
    }

    .footer-description-ubicanos {

        h4 {
            font-size: 30px !important;
        }
    }

    .li-effect {
        font-size: 20px !important;
    }

    .card-localizacion {
        h5 {
            font-size: 18px !important;
        }
    }

    .card-icloc {
        svg {
            width: 35px !important;
            height: 35px !important;
        }
    }

    .card-localizacion > h5:first-of-type {
        font-weight: 800 !important;
        font-size: 18px !important;
        margin-bottom: 0;
    }
    
    .card-localizacion > h5:last-of-type {
        font-weight: 400 !important;
        font-size: 18px !important;
        margin-bottom: 0;
    }
}

@media (min-width: 769px) and (max-width: 1480px) {
    .img-carousel {
        height: calc(100vh - 120px) !important;
        width: 100vw !important;
    }

    .carousel_container {
        width: 100vw;
    }

    #carouselExample {
        width: 100vw !important;
    }

    .amddi_internacional {
        width: 100%;
    }

    .footer_container {
        width: 100% !important;
    }

    .footer-description {
        width: 100%;
    }

    .footer-description-llamanos{
        flex-direction: column;
    }

    .footer-socialnetwork{
        width: 100%;
    }

.footer-description-amddi{
    width: 32%;
}

.footer-description-ubicanos{
    width: 55%;
}

}



