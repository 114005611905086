* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.amddi_container {
  max-height: 500vh;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-around !important;
  padding-top: 120px;
}

.carousel_nosotros {
  padding-top: 50px !important;
}

.carousel_nosotros {
  padding-top: 50px !important;
}

.quienessomos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 120px);
}

.quienessomos-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 399px;
  padding: 0 0 0 20px;
  border-radius: 10px;
  position: relative;
}

.quienessomos-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 30px;
}

.quienessomos-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.quienessomos_description {
  width: 550px;
  text-align: justify;
}
.quienessomos_description h1 {
  color: #666666 !important;
}
.quienessomos_description h4 {
  font-size: 18px !important;
  color: #666666 !important;
}

#accordionExample {
  width: 500px;
  padding: 20px 0 0 0 !important;
}

.img-logo2 {
  width: 400px;
}

.valores-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  background-image: url(../images/Nosotros/valores.jpg);
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 140vh !important;
}
.valores-container h2 {
  font-weight: 600;
}

.valores {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  width: 80%;
  padding-top: 20px;
}

.valores_primeros {
  width: 550px;
}

.valor_card {
  display: flex;
  height: 160px;
}

.valor_description {
  width: 450px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.valor_description h3 {
  font-size: 23px !important;
  color: #00d799 !important;
  height: 16px !important;
  font-weight: 700;
}
.valor_description p {
  text-align: justify;
}

.img-valor {
  height: 120px;
}

.accordion-button {
  color: white !important;
  background-image: url(../images/fonto_verde.jpeg);
  background-size: 500px 500px;
  background-repeat: repeat;
}

.accordion-body p {
  color: white !important;
}

.accordion-collapse {
  background-color: #00d799 !important;
}

.accordion-button::before {
  color: white;
  /* Set the color of the arrow to white */
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.accordion-button:not(.collapsed)::before {
  filter: brightness(0) invert(1);
}

.accordion-button::after {
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .amddi_container {
    width: 100%;
  }
  .img-valor {
    height: 70px !important;
  }
  .valores-container {
    height: 140% !important;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .valores-container h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .valor_card {
    width: 100%;
    height: 85px;
  }
  .valores_primeros {
    width: 100%;
  }
  .valores {
    padding-top: 0;
    width: 95%;
    flex-direction: column !important;
  }
  .valor_description {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .valor_description h3 {
    font-size: 15px !important;
  }
  .valor_description p {
    font-size: 10px !important;
  }
  .accordion {
    width: 350px !important;
  }
  .quienessomos {
    padding-top: 30px;
    height: 100% !important;
  }
  .quienessomos-card {
    flex-direction: column;
    width: 90%;
  }
  .quienessomos-container {
    height: 100%;
    padding-left: 0 !important;
  }
  .img-logo2 {
    width: 300px;
  }
  .quienessomos_description {
    margin-top: 20px;
    width: 100%;
  }
}
@media (min-width: 1540px) {
  .quienessomos {
    width: 70% !important;
  }
  .valores-container {
    height: 110vh !important;
  }
  .valores {
    width: 70%;
  }
  .valor_card {
    width: 100%;
  }
  .img-titulo h2 {
    font-size: 30px !important;
  }
  .quienessomos-descripcion {
    font-size: 30px !important;
  }
  .quienessomos-container {
    width: 1200px !important;
  }
  .quienessomos {
    width: 100%;
  }
  .quienessomos-descripcion {
    width: 680px;
  }
  .quienessomos-container {
    width: 70%;
    height: 90%;
    margin-top: 50px;
  }
  .quienessomos-card {
    width: 80%;
    margin-bottom: 80px;
  }
}
@media (min-width: 769px) and (max-width: 1536px) {
  #carouselExample {
    width: 100% !important;
  }
  .quienessomos-container {
    padding-left: 0;
    height: 90%;
  }
  .quienessomos-card {
    justify-content: space-around;
    margin-bottom: 0;
  }
  .valores {
    width: 95%;
  }
  .fila {
    height: 100%;
  }
  .img-logo2 {
    width: 35%;
  }
  .quienessomos_description h4 {
    font-size: 17px !important;
  }
  .valor_card {
    height: 180px !important;
  }
  .footer_container {
    height: auto !important;
  }
  .footer-description {
    height: 100%;
  }
  .valores_primeros {
    width: 47%;
  }
}
@supports (-webkit-appearance: none) {
  .quienessomos-container {
    min-width: 100%;
  }
  .quienessomos-card {
    min-width: 60% !important;
    margin-bottom: 30px;
  }
  .valores-container {
    min-width: 100%;
    min-height: 140vh;
  }
  .valores {
    min-width: 80%;
  }
  .img-logo2 {
    width: 400px;
  }
}
@media (max-width: 950px) {
  .img-logo2 {
    width: 250px !important;
  }
  .valor_description h3 {
    font-size: 17px !important;
  }
}/*# sourceMappingURL=Nosotros.css.map */