@charset "UTF-8";
* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.carousel_container {
  padding-top: 120px;
  width: 1481px;
  height: auto;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden;
}

.carousel {
  width: 1481px !important;
  margin: 0 0 0 0 !important;
}

.carousel-indicators button {
  background-color: #b4b4b4 !important;
  width: 80px !important;
  /* Cambia el ancho según tu preferencia */
  height: 20px !important;
  /* Cambia la altura según tu preferencia */
}

.carousel-indicators .active {
  background-color: #00d799 !important;
  margin: 0 0 0 0 !important;
}

.carousel-control-next-icon {
  width: 80px !important;
  height: 80px !important;
  background-color: #00d799 !important;
  filter: none !important;
  border-radius: 5px;
}

.carousel-control-prev-icon {
  width: 80px !important;
  height: 80px !important;
  background-color: #00d799 !important;
  filter: none !important;
  border-radius: 5px;
}

.button-carousel:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.negocio {
  position: relative;
}

.negocio_titulo {
  height: 300px;
  padding-top: 30px;
}

.negocio_descripcion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 1100px;
  justify-content: space-between;
}

.titulo_negocio_1 {
  color: #00d799 !important;
  font-weight: 900;
  font-size: 60px;
  height: 60px;
  margin-bottom: 0 !important;
}

.titulo_negocio_2 {
  color: #ffffff !important;
  font-weight: 900;
  font-size: 100px;
  height: 100px;
  margin-bottom: 0 !important;
}

.negocio_listas {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-top: 95px;
}

.negocio_list {
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}
.negocio_list li {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
}
.negocio_list span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 5px;
}

.contenedor_btn_proyecto {
  width: 500px;
  top: 80%;
  left: 20%;
}

.proyecto_titulo {
  height: 300px;
  padding-top: 30px;
}

.proyecto_descripcion {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 1100px;
  justify-content: space-between;
}

.titulo_proyecto_1 {
  font-size: 50px;
  height: 50px;
}

.titulo_proyecto_2 {
  font-size: 115px;
  font-weight: 900;
}

.proyecto_listas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.proyecto_list {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding-left: 100px;
  padding-bottom: 25px;
}
.proyecto_list li {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
}
.proyecto_list span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 5px;
}

.contacto_carousel {
  margin-top: 20px !important;
  width: 200px;
  padding-right: 12px !important;
  margin-bottom: 0 !important;
}

.contenedor-efecto {
  background-image: url(../images/adelante_completo.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.practica {
  position: relative;
}

.practicas_description {
  position: absolute;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 700px;
  justify-content: space-between;
}

.practicas_titulo {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.practicas_titulo h2 {
  font-weight: 900;
  font-size: 50px;
}

.color_verde {
  color: #00d799;
}

.indeed_btn {
  width: 700px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 3px solid #00d799;
  margin-top: 30px;
  font-size: 25px;
}

.indeed_btn:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.efecto-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  transition: 3s ease;
}

.contenido-texto {
  width: 100%;
  text-align: center;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contenido-texto p {
  font-size: 0.5em;
}

.efecto-scroll h2 {
  font-size: 5rem;
  color: #fff;
}
.efecto-scroll h2 span {
  z-index: 6;
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 0 0;
}

.efecto-scroll p {
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 0 0 10px #000;
}

.img-scroll {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.8;
}

.proyecto_contacto {
  display: none !important;
}

.amddi_internacional {
  height: calc(100vh - 120px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.img-carousel_int {
  height: calc(100vh - 120px);
}

.img_int_container {
  width: 50% !important;
}

.amdi_international_description {
  width: 80%;
}
.amdi_international_description .international_container h3:nth-of-type(1) {
  color: #00d799 !important;
  font-size: 35px !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}
.amdi_international_description .international_container h3:nth-of-type(2) {
  color: #00d799 !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
}
.amdi_international_description .international_desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.amdi_international_description .international_desc p {
  color: #000 !important;
  text-align: center;
  font-size: 18px;
}

/*  */
.carousel_infinito {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 40%;
  /* Estilo para las imágenes en el carrusel */
}
.carousel_infinito .carousel_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ocultar cualquier contenido que se desborde */
}
.carousel_infinito .slick-slider {
  width: 90%;
  height: 100%;
}
.carousel_infinito .scroll-item {
  width: 311px; /* Ancho deseado */
  height: 223px; /* Alto deseado */
  overflow: hidden; /* Recortar cualquier parte de la imagen que sea más grande */
}
.carousel_infinito .img_int_amddi {
  width: 311px;
  /* Ancho deseado */
  height: 223px;
  /* Alto deseado */
  -o-object-fit: cover;
     object-fit: cover;
  /* Ajustar la imagen para llenar el tamaño deseado sin deformarla */
}

/*  */
.seccion_contador {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seccion_contador .fila {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.seccion_contador .fila .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.seccion_contador .fila .col h3 {
  font-size: 40px !important;
  font-weight: 900 !important;
  color: #666666 !important;
}
.seccion_contador .fila .col h3 span {
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.seccion_contador .fila .col p {
  color: #666666 !important;
  font-size: 25px !important;
  font-weight: 900 !important;
}

.contador_num {
  background: linear-gradient(to right, #b47a1a, #ffe55a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.img_contador_1 {
  width: 250px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_contador {
  margin-bottom: 20px;
}

.contador-margin {
  margin-left: 10px;
}

.asesoria {
  position: relative;
}

.asesoria_subtitulo {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
}

.asesoria_descripcion {
  position: absolute;
  width: 100%;
  top: 10%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.asesoria_descripcion h2 {
  font-size: 60px;
  font-weight: 900;
  height: 60px;
  margin: 0 0 0 0 !important;
}
.asesoria_descripcion h2 span {
  color: #00d799;
}
.asesoria_descripcion h3 {
  font-size: 30px;
  font-weight: 700;
}
.asesoria_descripcion h3 span {
  color: #00d799;
}

.botones_contacto_asesoria {
  position: relative;
  bottom: 0 !important;
  left: 55% !important;
  z-index: 2;
  top: 78%;
  height: 100px;
}

.asesoria_lista {
  width: 600px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
}
.asesoria_lista li {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
}
.asesoria_lista span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 5px;
}

.medalla_container {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 30%;
}

.medalla {
  position: relative;
  z-index: 2;
}

.somos {
  position: relative;
  top: 90%;
  left: 8%;
  transform: translate(-50%, -10%);
}

.somos1 {
  position: relative;
  top: 90%;
  left: 18%;
  transform: translate(-50%, -10%);
}

.somos2 {
  left: 18%;
}

.maestria {
  position: relative;
}

.maestria_titulo {
  height: 300px;
  padding-top: 30px;
}

.maestria_descripcion {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 1100px;
  justify-content: space-between;
  flex-direction: column;
}

.titulo_maestria_1 {
  color: #00d799 !important;
  font-weight: 900;
  font-size: 40px;
  height: 40px;
  margin-bottom: 0 !important;
}

.titulo_maestria_2 {
  color: #ffffff !important;
  font-weight: 900;
  font-size: 80px;
  height: 80px;
  margin-bottom: 0 !important;
}

.maestria_listas {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70%;
}

.maestria_list {
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}
.maestria_list li {
  padding-left: 20px;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
}
.maestria_list span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 5px;
}

.doctorado {
  position: relative;
}

.doctorado_titulo {
  height: 300px;
  padding-top: 30px;
}

.doctorado_descripcion {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 1100px;
  justify-content: space-between;
  flex-direction: column;
}

.doctorado_titulos_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 480px;
}

.titulo_doctorado_1 {
  color: #ffffff !important;
  font-weight: 900;
  font-size: 45px;
  height: 45px;
  margin-bottom: 0 !important;
}
.titulo_doctorado_1 span {
  color: #00d799;
}

.titulo_doctorado_2 {
  color: #ffffff !important;
  font-weight: 900;
  font-size: 45px;
  height: 45px;
  margin-bottom: 0 !important;
}
.titulo_doctorado_2 span {
  color: #00d799;
}

.doctorado_experiencia {
  padding: 15px 25px 5px 25px;
  top: 60%;
  width: 600px;
  border: solid 3px #00d799;
  border-radius: 10px;
  position: absolute;
}
.doctorado_experiencia p {
  font-size: 25px;
  text-align: center;
}

.doctorado_listas {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 120%;
}

.medalla_container_doctorado {
  position: absolute;
  top: 60%;
  left: 55%;
}

.paises_list {
  display: flex;
  list-style: none;
  justify-content: space-around;
  text-decoration: none !important;
  padding-left: 0;
  width: 100%;
}
.paises_list li {
  text-decoration: none !important;
}

.btn_asesoria {
  display: none;
  visibility: hidden;
}

.seccion_servicios_p {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../images/fondodegrade.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.seccion_servicios_p h2 {
  font-size: 40px !important;
  font-weight: 800 !important;
  color: #00d799 !important;
}

.servicios_list_p {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.servicio_p {
  position: relative;
  width: 300px;
}

.img_servicio_p {
  width: 100%;
  display: block;
}

.servicio_titulo_p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.servicio_titulo_p h3 {
  margin: 0;
  width: 100%;
  font-size: 25px !important;
}

.servicio_p {
  overflow: hidden;
  transition: transform 1s;
}

.servicio_p img {
  transition: transform 0.3s;
}

.servicio_p:hover img {
  transform: scale(1.2);
}

.servicio_p:hover {
  transform: scale(1.05);
}

.titulo_2 {
  display: none;
}

.animated {
  animation: slide-in 0.5s ease;
  opacity: 1;
  /* Mantener la opacidad en 1 para que los elementos sean visibles */
}

.img_responsive {
  display: none;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.col p {
  text-align: center;
}

@media (max-width: 768px) {
  .contacto_carousel {
    width: 150px !important;
  }
  .titulo_2 {
    display: block;
  }
  .img_principal {
    display: none;
  }
  .img_responsive {
    display: block;
  }
  .img-carousel {
    width: 100%;
  }
  .carousel_container {
    width: 100%;
  }
  .carousel {
    width: 100% !important;
  }
  .negocio_descripcion {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
  }
  .negocio_titulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 200px;
  }
  .titulo_negocio_1 {
    font-size: 30px;
    height: 30px;
  }
  .titulo_negocio_2 {
    font-size: 75px;
    height: 75px;
  }
  .negocio_listas {
    padding-top: 0;
    flex-direction: column;
  }
  .contacto-whatsapp {
    display: none !important;
  }
  .negocio_list {
    margin-bottom: 0 !important;
    padding-left: 12px;
  }
  .negocio_list li {
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .contacto {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .practicas_description {
    width: 100%;
    top: 40%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .practicas_titulo {
    width: 90%;
    padding-left: 30%;
  }
  .practicas_titulo h2 {
    text-align: center;
    font-size: 30px;
  }
  .indeed_btn {
    margin-left: 30%;
    width: 200px;
    font-size: 15px;
    height: 60px;
    text-align: center;
  }
  .proyecto_titulo {
    width: 100%;
  }
  .titulo_proyecto_1 {
    font-size: 30px;
  }
  .titulo_proyecto_2 {
    font-size: 60px;
  }
  .proyecto_descripcion {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .proyecto_titulo {
    width: 85%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .proyecto_listas {
    padding-top: 0;
  }
  .proyecto_listas li {
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .proyecto_list {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .proyecto_contacto {
    display: block !important;
  }
  .proyecto_last {
    padding-bottom: 30px;
  }
  .img_int_container {
    display: none !important;
  }
  .amddi_internacional {
    width: 100%;
  }
  .amddi_internacional {
    height: 100%;
  }
  .international_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0 !important;
  }
  .international_desc p {
    padding: 0 10px 0 10px;
  }
  .amdi_international_description {
    padding-top: 20px;
    width: 100%;
  }
  .amdi_international_description .international_container h3:nth-of-type(1) {
    font-size: 25px !important;
  }
  .amdi_international_description .international_container h3:nth-of-type(2) {
    font-size: 35px !important;
  }
  .contenedor-efecto {
    background-image: url(../images/adelante_completo.png);
    width: 100vw;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .img-scroll {
    visibility: hidden;
  }
  .efecto-scroll {
    height: 258.13px !important;
    transition: 2s ease;
  }
  .contenido-texto {
    width: 380px;
    top: 60%;
  }
  .contenido-texto h2 {
    font-size: 20px !important;
  }
  .contenido-texto p {
    font-size: 15px !important;
  }
  .informacion_btn {
    width: 300px !important;
  }
  .pais {
    height: 25px;
  }
  .seccion_contador {
    width: 100%;
    height: 100%;
    margin-top: 30px;
  }
  .fila {
    flex-direction: column;
  }
  .asesoria_titulo {
    width: 90% !important;
    height: auto !important;
  }
  .asesoria_titulo h2 {
    font-size: 40px;
    height: auto !important;
  }
  .asesoria_titulo .asesoria_subtitulo {
    width: 100%;
    margin-top: 20px;
  }
  .asesoria_titulo .asesoria_subtitulo h3 {
    font-size: 18px !important;
    height: auto !important;
  }
  .titulo_principal {
    display: none;
  }
  .asesoria_lista {
    padding-left: 16%;
    width: 100%;
    justify-content: center;
  }
  .asesoria_lista li {
    width: 68%;
    font-weight: 300;
    font-size: 15px;
  }
  .asesoria_lista li span {
    width: 15px;
    height: 15px;
  }
  .asesoria_descripcion {
    top: 4%;
    left: 0;
  }
  .medalla_container {
    top: -50%;
    left: 10%;
  }
  .medalla {
    height: 120px;
    width: 120px;
  }
  .somos {
    width: 240px;
    height: 70px;
    left: 15%;
  }
  .btn_asesoria {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    display: block;
    width: 200px;
    visibility: visible;
    background: #00d799 !important;
  }
  .carousel-indicators {
    display: none !important;
  }
  .medalla_container {
    top: 80%;
    left: 10%;
    position: static !important;
  }
  .doctorado {
    width: 100%;
  }
  .doctorado_descripcion {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .doctorado_titulos_list {
    width: 100%;
    padding-right: 25%;
    padding-left: 10%;
  }
  .doctorado_titulos_list .titulo_doctorado_1 {
    width: 100%;
    font-size: 25px !important;
    height: 30px;
  }
  .doctorado_titulos_list .titulo_doctorado_2 {
    width: 100%;
    font-size: 25px !important;
    height: 30px;
  }
  .doctorado_experiencia {
    width: 300px;
    top: 70%;
    left: 12%;
  }
  .doctorado_experiencia p {
    font-size: 15px;
    font-weight: 300;
  }
  .doctorado_titulo {
    padding-top: 80px;
  }
  .doctorado_contacto {
    display: none !important;
  }
  .doctorado_listas {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medalla_container_doctorado {
    width: 100%;
    top: 5%;
    left: 5%;
  }
  .maestria_descripcion {
    top: 40%;
    width: 100%;
  }
  .maestria_descripcion .titulo_maestria_1 {
    font-size: 20px;
    height: 20px;
    padding-left: 20px;
  }
  .maestria_descripcion .titulo_maestria_2 {
    font-size: 35px;
    padding-left: 20px;
  }
  .maestria_listas {
    width: 100%;
    height: 100px;
    flex-direction: column;
    padding-right: 50px;
  }
  .maestria_list {
    margin-bottom: 0;
  }
  .maestria_list li {
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .seccion_servicios_p {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .seccion_servicios_p h2 {
    text-align: center;
  }
  .servicios_list_p {
    flex-direction: column;
  }
  .servicio_p {
    margin-bottom: 10px;
  }
  .carousel-control-prev-icon {
    width: 50px !important;
    height: 50px !important;
  }
  .carousel-control-next-icon {
    width: 50px !important;
    height: 50px !important;
  }
  .contacto_tesis_prev {
    margin-top: 0 !important;
  }
  .scroll-item {
    width: 311px !important;
    height: 223px !important;
  }
}
@media (min-width: 1500px) {
  .caracterisctia_servicios_container {
    width: 800px;
    height: 800px;
  }
  .img-carousel {
    height: calc(100vh - 120px) !important;
    width: 100vw !important;
  }
  .carousel_container {
    width: 100vw;
  }
  #carouselExample {
    width: 100vw !important;
  }
  .servicios_descripcion {
    font-size: 18px !important;
  }
  .lista_servicios li {
    font-size: 18px !important;
  }
}
/* Ajusta los estilos según sea necesario */
@media (min-width: 769px) and (max-width: 1480px) {
  .scroll-item {
    width: calc(50% - 10px);
    /* Una imagen por fila */
  }
  .img-carousel {
    height: calc(100vh - 120px) !important;
    width: 100vw !important;
  }
  .carousel_container {
    width: 100vw;
  }
  #carouselExample {
    width: 100vw !important;
  }
  .amddi_internacional {
    width: 100%;
  }
  .amdi_international_description {
    width: 80% !important;
    margin-right: 100px;
  }
  .img-carousel_int {
    width: 150%;
  }
  .carousel-control-prev-icon {
    width: 50px !important;
    height: 50px !important;
  }
  .carousel-control-next-icon {
    width: 50px !important;
    height: 50px !important;
  }
  .quienessomos {
    width: 100vw !important;
  }
  .img-logo2 {
    width: 300px;
  }
  .quienessomos-card {
    width: 90% !important;
    justify-content: space-around;
  }
  .valor_description {
    width: 380px !important;
  }
  .valor_description h3 {
    font-size: 15px !important;
  }
  .valor_description p {
    font-size: 15px !important;
  }
  .img-valor {
    height: 70px !important;
  }
  .valor_card {
    justify-content: center;
    align-items: center;
  }
  .servicio_p {
    width: 22%;
  }
  .servicio_titulo_p h3 {
    font-size: 20px !important;
  }
  .international_container {
    width: 100%;
  }
  .img-carousel_int {
    width: 100%;
  }
  .col {
    width: 25% !important;
  }
  .img_int_container {
    display: none;
  }
  .amdi_international_description {
    margin-right: 0;
  }
}/*# sourceMappingURL=Carousel.css.map */