* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.footer-description {
  height: 580px;
  width: 1481px;
  background-image: url(../images/Footer/fondo_footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  padding-top: 20px;
}

.footer-description-amddi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
}
.footer-description-amddi h4 {
  font-weight: 900 !important;
  font-size: 20px !important;
}

.footer-content {
  margin-top: 20px;
  padding: 0 30px 0 30px;
  text-align: justify;
  width: 95%;
}
.footer-content h5 {
  font-size: 17px !important;
  font-weight: 300 !important;
  color: #ffffff;
}

.footer-socialnetwork {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.footer-socialnetwork a img {
  margin-left: 5px;
}

.svg-effect {
  transition: filter 0.3s;
}

.svg-effect:hover {
  animation: scale 1s infinite;
}

.li-effect:hover {
  animation: scale 1s infinite;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.margin_title {
  margin-top: 20px !important;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
.footer-description-ubicanos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 65%;
  margin-bottom: 40px;
}
.footer-description-ubicanos h4 {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #ffffff;
}

.footer-description-llamanos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  padding-top: 30px;
}

.llamanos_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horario_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-ictel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-icloc {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.card-icloc h5 {
  font-size: 16px !important;
  font-weight: 800 !important;
  color: #ffffff;
}

.card-localizacion {
  padding-left: 10px;
  display: flex !important;
  align-items: center;
  width: 100%;
}

.card-localizacion > h5:first-of-type {
  font-weight: 800 !important;
  color: #ffffff !important;
  margin-bottom: 0;
}

.card-localizacion > h5:last-of-type {
  font-weight: 400 !important;
  margin-bottom: 0;
}

.bi-geo-alt {
  padding-top: 5px !important;
}

.container-ictel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.container-ictel h5 {
  font-weight: 400 !important;
  color: #ffffff !important;
  margin-bottom: 0;
}

.footer-text {
  padding-top: 15px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600 !important;
  color: #666666 !important;
}

.footer-description-pago {
  padding-top: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pago-container img {
  padding-left: 5px;
}

.libro-container {
  padding-bottom: 10px;
}

.container-list {
  width: 100%;
  margin-bottom: 0 !important;
  list-style: none !important;
  text-decoration: none;
}
.container-list li {
  list-style: none !important;
  margin-bottom: 5px !important;
}
.container-list li h5 {
  color: #00d799 !important;
}

.servicios {
  text-decoration: none !important;
}

.servicios_l {
  color: #00d799 !important;
  font-size: 17px !important;
  font-weight: 700 !important;
}

.footer_contacto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.footer_contacto {
  display: flex;
  align-items: center;
}

.title_ubi {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .footer_container {
    width: 100%;
  }
  .footer-description {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .footer-text {
    font-size: 12px;
  }
  .container-list {
    width: 90%;
  }
  .footer-description-amddi {
    width: 90%;
  }
  .footer-socialnetwork {
    justify-content: space-around;
  }
  .footer-description-ubicanos {
    justify-content: center;
    width: 90%;
  }
  .footer-description-ubicanos h4 {
    text-align: center;
    margin-right: 0 !important;
  }
  .container-list {
    padding-left: 0;
  }
  .container-list ul {
    padding-left: 0;
  }
  .footer-content {
    padding: 0 0 0 0;
  }
  .footer_contacto {
    flex-direction: column;
  }
  .card-localizacion {
    flex-direction: column;
  }
  .card-localizacion h5 {
    text-align: center;
  }
  .card-icloc svg {
    display: none;
  }
  .img_tesis {
    height: 150px;
  }
  .pago-container img {
    height: 30px;
  }
  .card-icloc {
    margin-bottom: 5px;
  }
}
@media (min-width: 1500px) {
  .footer_container {
    width: 100% !important;
    font-weight: 600;
  }
  .footer-description {
    width: 100%;
    height: 700px;
  }
  .footer-content h5 {
    font-size: 20px !important;
  }
  .footer-description-amddi h4 {
    font-size: 30px !important;
  }
  .container-ictel h5 {
    font-size: 20px !important;
  }
  .footer-description-llamanos {
    width: 100%;
  }
  .card-ictel svg {
    width: 30px !important;
    height: 30px !important;
  }
  .footer-description-pago {
    margin-top: 5px;
    width: 100%;
  }
  .svg-effect {
    height: 50px;
  }
  .footer-description-redes {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pago-container img {
    height: 35px;
  }
  .pro_list {
    color: #00d799 !important;
  }
  .footer-description-ubicanos h4 {
    font-size: 30px !important;
  }
  .li-effect {
    font-size: 20px !important;
  }
  .card-localizacion h5 {
    font-size: 18px !important;
  }
  .card-icloc svg {
    width: 35px !important;
    height: 35px !important;
  }
  .card-localizacion > h5:first-of-type {
    font-weight: 800 !important;
    font-size: 18px !important;
    margin-bottom: 0;
  }
  .card-localizacion > h5:last-of-type {
    font-weight: 400 !important;
    font-size: 18px !important;
    margin-bottom: 0;
  }
}
@media (min-width: 769px) and (max-width: 1480px) {
  .img-carousel {
    height: calc(100vh - 120px) !important;
    width: 100vw !important;
  }
  .carousel_container {
    width: 100vw;
  }
  #carouselExample {
    width: 100vw !important;
  }
  .amddi_internacional {
    width: 100%;
  }
  .footer_container {
    width: 100% !important;
  }
  .footer-description {
    width: 100%;
  }
  .footer-description-llamanos {
    flex-direction: column;
  }
  .footer-socialnetwork {
    width: 100%;
  }
  .footer-description-amddi {
    width: 32%;
  }
  .footer-description-ubicanos {
    width: 55%;
  }
}/*# sourceMappingURL=Footer.css.map */