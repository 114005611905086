* {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 30px;
  width: 140px !important;
  color: #ffffff !important;
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 40px !important;
  font-weight: 900 !important;
  display: inline;
  text-align: justify !important;
}

h2 {
  color: #ffffff !important;
}

h3 {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

h4 {
  font-size: 20px !important;
  text-align: justify !important;
  display: inline;
  margin: 0 !important;
  padding-bottom: 10px !important;
}

h5 {
  font-size: 15px !important;
}

p {
  color: #ffffff !important;
}

a,
ul,
li {
  text-decoration: none !important;
}

input {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Para ocultar las flechas en Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-moz-placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

input::placeholder {
  color: #666666 !important;
  opacity: 1 !important;
}

textarea {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

textarea::-moz-placeholder {
  color: #666666 !important;
}

textarea::placeholder {
  color: #666666 !important;
}

select {
  color: #666666 !important;
  border-color: #00d799 !important;
  border: 3px solid #00d799 !important;
}

#root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-effect {
  color: #ffffff;
  background-color: #00d799 !important;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 40px 20px;
}

.button-effect:hover {
  animation: scale 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.whatsapp-icon {
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp-icon:hover {
  animation: scale 1s infinite;
}

.zoom {
  transition: 0.8s ease-in-out;
}

.zoom:hover {
  transform: scale(1.01);
}

.testimonios_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 1481px;
  margin-right: 0;
  padding-top: 90px;
}
.testimonios_container .wrapper {
  width: 1000px !important;
  height: 700px;
  text-align: center;
}
.testimonios_container .wrapper h2 {
  font-weight: 900;
  font-size: 35px;
}
.testimonios_container .wrapper:hover {
  cursor: pointer;
}
.testimonios_container .carousel2 {
  padding-top: 20px !important;
  width: 1000px !important;
  margin: 0px auto;
  height: 1000px !important;
}
.testimonios_container .slick-list {
  height: 620px !important;
}
.testimonios_container .slick-slide {
  margin: 1px;
}
.testimonios_container .slick-slide img {
  width: 100%;
}
.testimonios_container .slick-prev,
.testimonios_container .slick-next {
  border-radius: 15px;
  border-color: transparent;
  background: #00d799 !important;
  height: 40px !important;
  width: 40px !important;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px !important;
}
.testimonios_container .slick-prev:before {
  font-size: 40px !important;
}
.testimonios_container .slick-next:before {
  font-size: 40px !important;
}
.testimonios_container .card {
  border: 0px solid #ffffff !important;
}
.testimonios_container .card-body {
  background: #fff;
  width: 100%;
  vertical-align: top;
}
.testimonios_container .card-content {
  text-align: left;
  color: #00d799;
  padding: 15px;
}
.testimonios_container .card-text {
  font-size: 14px;
  font-weight: 300;
}
.testimonios_container .slick-dots li button:before {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  color: #00d799 !important;
}
.testimonios_container .slick-dots {
  bottom: -50px !important;
}
.testimonios_container .titulo-verde {
  color: #00d799 !important;
}

@supports (-webkit-appearance: none) {
  .testimonios_container {
    min-height: 120vh;
  }
}
@media (max-width: 768px) {
  .testimonios_container {
    width: 100% !important;
    height: 750px !important;
    padding-top: 70px !important;
  }
  .testimonios_container .wrapper {
    width: 100% !important;
    height: 500px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .testimonios_container .carousel2 {
    width: 80% !important;
    margin: 0 0 0 0 !important;
  }
  .testimonios_container .card-body {
    width: 100%;
  }
  .testimonios_container .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonios_container .slick-slide {
    margin: 0 !important;
  }
  .testimonios_container .slick-list {
    height: 450px !important;
  }
}
@media (min-width: 769px) and (max-width: 1599px) {
  .testimonios_container {
    width: 100% !important;
    height: 115vh !important;
    margin-top: 70px;
  }
  .testimonios_container .carousel2 {
    height: 100% !important;
  }
  .testimonios_container .slick-list {
    height: 550px !important;
  }
  .testimonios_container .img_contador {
    width: 280px !important;
    height: 540px;
  }
}
@media (min-width: 1600px) {
  @supports (-webkit-appearance: none) {
    .testimonios_container {
      min-height: 100vh;
    }
    .carousel2 {
      height: 100% !important;
    }
    .slick-list {
      height: 550px !important;
    }
    .img_contador {
      width: 280px !important;
      height: 540px;
    }
  }
}/*# sourceMappingURL=Testimonios.css.map */