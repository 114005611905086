@import 'Variables';

.testimonios_container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 1481px;
    margin-right: 0;
    padding-top: 90px;
    // height:125vh;
    .wrapper {
        width: 1000px !important;
        height: 700px;
        text-align: center;

        h2 {
            font-weight: 900;
            font-size: 35px;
        }
    }

    .wrapper:hover {
        cursor: pointer;
    }


    .carousel2 {
        padding-top: 20px !important;
        width: 1000px !important;
        margin: 0px auto;
        height: 1000px !important;
    }

    .slick-list {
        height: 620px !important;

    }

    .slick-slide {
        margin: 1px;
    }

    // .slick-slide {
    //     transition: transform 0.3s;
    //     margin: 0;
    // }

    .slick-slide img {
        width: 100%;
    }

    .slick-prev,
    .slick-next {
        // background: #000;
        border-radius: 15px;
        border-color: transparent;
        background: $verde-lima !important;
        height: 40px !important;
        width: 40px !important;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px !important;
    }

    .slick-prev:before {
        font-size: 40px !important;
    }

    .slick-next:before {
        font-size: 40px !important;
    }

    .card {
        // width: 280px !important;
        // height: 400px !important;
        border: 0px solid $blanco !important;
        // box-shadow: 1px 1px 15px #ccc;
    }

    .card-body {
        background: #fff;
        width: 100%;
        vertical-align: top;
    }

    .card-content {
        text-align: left;
        color: $verde-lima;
        padding: 15px;
    }

    .card-text {
        font-size: 14px;
        font-weight: 300;
    }

    .slick-dots li button:before {
        font-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
        color: $verde-lima !important;
    }

    .slick-dots {
        bottom: -50px !important;
    }


    .titulo-verde {
        color: $verde-lima !important;
    }
}

@supports (-webkit-appearance: none) {
    .testimonios_container {
        min-height: 120vh;
    }

}


@media (max-width: 768px) {
    .testimonios_container {
        width: 100% !important;
        height: 750px !important;
        padding-top: 70px !important;
        
    .wrapper {
        width: 100% !important;
        height: 500px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carousel2 {
        width: 80% !important;
        margin: 0 0 0 0 !important;
    }

    .card-body {
        width: 100%;
    }

    .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .slick-slide {
        margin: 0 !important;
    }

    .slick-list {
        height: 450px !important;
    }
    }


}

@media (min-width: 769px) and (max-width: 1599px) {
    .testimonios_container {
        width: 100% !important;
        height: 115vh !important;
        margin-top: 70px;
        .carousel2 {
            height: 100% !important;
        }
    
        .slick-list {
            height: 550px !important;
        }
    
        .img_contador {
            width: 280px !important;
            height: 540px;
        }
    
    }


}

@media (min-width: 1600px) {
    // .testimonios_container {
    //     width: 100% !important;
    //     height: calc(100vh - 70px) !important;
    //     // margin-top: 70px;
    //     .carousel2 {
    //         height: 100% !important;
    //     }
    
    //     .slick-list {
    //         height: 550px !important;
    //     }
    
    //     .img_contador {
    //         width: 280px !important;
    //         height: 540px;
    //     }
    // }

    @supports (-webkit-appearance: none) {
        .testimonios_container {
            min-height: 100vh;
        }
        .carousel2 {
            height: 100% !important;
        }
    
        .slick-list {
            height: 550px !important;
        }
    
        .img_contador {
            width: 280px !important;
            height: 540px;
        }
    
    }
    

}