@import 'Variables';


.registro_container {
    width: 600px;
    height: 120vh;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Estilos para el formulario */
.form_registro_container {
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    // background-color: $verde-lima;
    border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Estilos para los campos de entrada */
.input_registro {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Estilos para el botón de enviar */
.form_registro button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form_registro button[type="submit"]:hover {
    background-color: #0056b3;
}

.secciones_registro_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.bg_green{
    background: $verde-lima !important;
}

.linea_green{
    background-color: $verde-lima !important;
}

/*  */
.linea_entre_circulos {
    flex: 1;
    height: 5px; /* Grosor de la línea */
    background-color: $gris-claro /* Color de la línea */
}
/*  */

.secciones_registro{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // border: 1px solid $gris-oscuro;
    background: $gris-claro;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: $blanco;
    // margin-bottom: 10px;/*  */
}

.titulo_registro{
    color: $verde-lima !important;
    font-weight: 900;
    margin-bottom: 20px;
}

.form_registro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.button_logearse{
    margin-top: 20px !important;
    margin-bottom: 0 !important;
}

.input_registro_codigo {
    width: 50px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.input_especialidad{
    margin-bottom: 20px;
}

.form_registro_codigo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    h3{
        font-size: 15px !important;
        color: $verde-lima !important;
    }
}

.input_card{
    width: 100%;
}

.codigo_partes{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
}

.input_codigo{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    font-size: 20px;
    text-align: center !important;
}

.pais_select{
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .secciones_registro_container{
        width: 310px;
    }
    .codigo_partes{
        width: 310px;
    }
    .input_codigo{
        width: 50px !important;
        height: 50px;
    }
    .form_registro_codigo{
        width: 310px;
    }
}