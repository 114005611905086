@import 'Variables';

.servicios_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../images/fondo_servicios.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed;
    background-size: 100vw 100%;

}

.servicio_container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portada_servicios {
    margin-top: 120px;
    height: 30vh;
    width: 100%;
    background-image: url(../images/portada_servicios.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.portada_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
}

.portada_titulo {
    h3 {
        font-size: 60px !important;
        font-weight: 900 !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;

        span {
            color: $verde-lima;
        }
    }
}

.confiables_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 20px;
    position: relative;
    // border-image: linear-gradient(to right, #b47a1a, #ffe55a);
    // border-radius: 10px !important;
    // border-image-slice: 1;

    h4 {
        font-size: 40px !important;
        font-weight: 900 !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
        border-radius: 10px;

        span {
            background: linear-gradient(to right, #b47a1a, #ffe55a);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.confiables_container::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 5px;
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}



.servicios_card {
    height: 400px;
    width: 60%;
    margin-top: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        text-align: center;
    }
}

.servicios_card::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 5px;
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.card_servicio {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    a {
        width: 250px;
    }

    button {
        position: absolute;
        bottom: -5%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
        color: $blanco;
        font-weight: 600 !important;
        z-index: 2;
        transform: scale(1.1);
    }

    button:hover {
        // bottom: -5%;
        animation: scale 1s infinite !important;
        transform-origin: center;
        color: $blanco;
    }

}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.card_title {
    max-width: 500px !important;
    background: $verde-lima;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;

    h2 {
        font-size: 35px !important;
        font-weight: 900 !important;
        margin: 0 !important;
    }
}



.carreras_container {
    height: 800px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
}

.servicios_caracteristicas {
    width: 1200px;
    height: 800px;
    // background: $verde-lima;
    background-image: url(../images/fonto_verde.jpeg);
    background-size: 500px 500px;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 0px 20px 0px 20px;
    margin-top: 140px;

    .titulo2 {
        display: inline-block;

        h2 {
            display: inline-block;
            text-align: center !important;
        }
    }
}

.servicios_titulo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.servicios_carreras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    li {
        color: $blanco;
    }
}

.servicios_list {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.servicio_description {
    width: 90%;
    padding: 20px 30px 20px 30px;
    text-align: justify;
    margin-top: 20px;

    p {
        color: $negro !important;
        font-size: 20px !important;
    }
}

.boton_servicios {
    border-radius: 10px;
    width: 250px;
    background: linear-gradient(to right, #b47a1a, #ffe55a);
    margin-bottom: 0px !important;
    color: $blanco;
}




.biologicas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    border: 1px solid $blanco !important;

    h3 {
        align-items: center;
    }

    ul {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }
}

.ingenieria {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    // background-image: url(../images/fondo_blanco.jpeg);
    background-size: 500px 500px;
    background-repeat: repeat;
    border: 1px solid $blanco !important;
    // h3{
    //     color: $verde-lima !important;
    // }
    //     ul{
    //         list-style: none !important;
    //         text-decoration: none !important;
    //         li{
    //             text-decoration: none !important;
    //             padding-right: 10px;
    //             color: $verde-lima !important;
    //             font-weight: 800;
    //             text-shadow: black 0.01em 0.01em 0.01em;
    //     }
    // }
}

.basicas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    border: 1px solid $blanco !important;

    ul {
        padding-right: 10px;
    }
}

.sociales {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    border: 1px solid $blanco !important;
}

.empresariales {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    border: 1px solid $blanco !important;
}

.salud {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    border: 1px solid $blanco !important;
}

.carrera_grupo {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 20px;
}

.im_carousel_carreras {
    width: 500px !important;
    height: 600px !important;
}

// #carreras{
//     margin-top: -100px;
// }
.carreras_container_1 {
    // margin-top: 120px;
    position: relative;
    height: 100vh;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .carrera_description {
        margin-top: 10px;

        h5 {
            color: $verde-lima;
            font-size: 35px !important;
            text-align: center;
            span {
                background: linear-gradient(to right, #b47a1a, #ffe55a);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .carreras_titulo {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container_slider {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

    }


    // .swiper_servicios{
    //     position: absolute !important;
    //     bottom: 0 !important;
    // }

    .slider {
        width: 90% !important;
        height: 300px !important;
        height: auto !important;
        margin: 0 auto;

    }

    .carousel {
        margin: 0 -10px;
        /* Ajusta el margen para el espaciado entre las imágenes */
        overflow: hidden;
        /* Evita que las imágenes sobresalgan del contenedor */
    }

    // .slick-list {
    //     height: 400px;
    // }

    // .slick-track {
    //     height: 400px;
    // }

    // .slick-slider {
    //     height: 400px;
    // }

    .slick-slide {
        transition: transform 0.3s;
        margin: 0;
    }

    .slick-center {
        transition: transform 0.5s;
        transform: scale(1.3);
        z-index: 15 !important;
        opacity:1;
        /* Hace que la imagen del medio sea un 20% más grande */
    }

    // .slick-list {
    //     margin: 0 0px;
    //     /* Espacios adicionales para centrar correctamente las imágenes */
    // }

    .slick-slide img {
        width: 100%;
        height: 100%;
        display: block !important;
        margin: 0 auto;
        overflow: visible !important;
        object-fit: cover !important;
        z-index: 10;
        transform: scale(0.8);
        cursor: pointer;
        margin: 0 10px;
        /* Ajusta el margen entre las imágenes */
        /* Agrega transición para el escalado y la opacidad */
    }

    .slick-slide:not(.slick-center) img {
        opacity: 0.5;
        /* Ajusta la opacidad según lo que prefieras */
    }

    // .slick-dots {
    //     bottom: -120px !important;
    // }
    .slick-dots li button:before {
        font-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
        color: $verde-lima !important;
    }

}



.carreras_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.botones_carreras {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.botones_carreras button {
    background: $gris-claro;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 10px;
}

.botones_carreras button.activo {
    background: $verde-lima;
    box-shadow: 2px 2px 10px #999;
}

.imagen {
    width: 500px;
    height: 500px;
    object-fit: contain;
    border-radius: 50px;
}


@media (max-width: 768px) {
    .img_logo_contacto {
        display: none !important;
    }

    .contacto-desc {
        width: 90% !important;
    }

    .prueba {
        height: auto;
    }

    .imagen {
        width: 90%;
        height: auto;
    }

    .servicios_container {
        height: auto;
    }

    .carreras_container {
        height: 100%;
    }

    .servicios_caracteristicas {
        width: 320px;
        height: 100%
    }

    .servicios_card {
        height: 70%;
        width: 90%;
    }

    .servicios_carreras {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .servicios_titulo {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 0;
    }

    .ingenieria {
        margin-top: 5px;
    }

    .servicios_list {
        margin-top: 5px;
    }

    .carrera_grupo {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .sociales {
        margin-top: 20px;
    }


    .portada_container {
        flex-direction: column;
        width: 100%;
    }

    .portada_titulo {
        h3 {
            font-size: 35px !important;
        }
    }

    .confiables_container {
        h4 {
            font-size: 20px !important;
        }
    }

    .card_title {
        width: 80% !important;

        h2 {
            font-size: 20px !important;
        }
    }

    .servicio_description {
        p {
            font-size: 15px !important;
        }
    }
    .slider {
        width: 90% !important;
        height: 300px !important;
        height: auto !important;
        margin: 0 auto;
        .slick-slide {
            transition: transform 0.3s;
            margin: 0; /* Ajusta el margen entre las imágenes */
        }
        
        .slick-center {
            transition: transform 0.5s;
            transform: scale(1.2);
            z-index: 15 !important;
        }
        
        .slick-slide img {
            width: 400px;
            height: 100%;
            display: block !important;
            margin: 0 auto;
            overflow: visible !important;
            object-fit: cover !important;
            z-index: 10;
            transform: scale(0.8);
            cursor: pointer;
            opacity: 0.7; /* Opacidad para las imágenes no centrales */
        }
        
        .slick-center img {
            opacity: 1; /* Imagen central al 100% de opacidad */
        }
}

.carreras_titulo {
    .carrera_description {
        h5 {
            font-size: 18px !important;
        }
    }
}

.carreras_container_1{
    height: calc(100vh - 120px);
}
}

@media (min-width: 1500px) {
    // .carreras_container {
    //     height: calc(100vh - 120px) !important;
    //     margin-top: 50px;
    //     justify-content: center;
    //     align-items: center;
    // }

    .servicios_card {
        width: 65%;
    }

    .servicio_description {
        width: 95%;

        p {
            font-size: 20px !important;
        }
    }

// .img_profesiones{
    
// }

    // .carreras_container_1{
    //     .slick-dots {
    //         bottom: -130px !important;
    //     }
    // }

}

@media (min-width: 910px) and (max-width: 1300px) {
    .portada_container {
        width: 80%;
    }

    .card_title {
        h2 {
            font-size: 25px !important;
        }
    }

    .servicio_description {
        p {
            font-size: 17px !important;
        }
    }

    .img_header1 {
        height: 50px;
    }

    .carreras_titulo {
        .carrera_description {
            h5 {
                font-size: 25px !important;
            }
        }
    }
    .carreras_container_1{
        height: calc(100vh - 120px);
    }
}

@media (min-width: 768px) and (max-width: 909px) {
    .portada_container {
        width: 100%;
    }

    .card_title {
        h2 {
            font-size: 25px !important;
        }
    }

    .servicio_description {
        p {
            font-size: 17px !important;
        }
    }

    .slider {
        width: 90% !important;
        height: 300px !important;
        height: auto !important;
        margin: 0 auto;
        .slick-slide {
            transition: transform 0.3s;
            margin: 0; /* Ajusta el margen entre las imágenes */
        }
        
        .slick-center {
            transition: transform 0.5s;
            transform: scale(1.2);
            z-index: 15 !important;
        }
        
        .slick-slide img {
            width: 60%;
            height: 100%;
            display: block !important;
            margin: 0 auto;
            overflow: visible !important;
            object-fit: cover !important;
            z-index: 10;
            transform: scale(0.8);
            cursor: pointer;
            opacity: 0.7; /* Opacidad para las imágenes no centrales */
        }
        
        .slick-center img {
            opacity: 1; /* Imagen central al 100% de opacidad */
        }
}
}