@import 'Variables';

.investigacion_card{

    min-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // background-image: url(../images/Programa/invsostenible.png);
    // background-size: 100% 100%; /* La imagen ocupará todo el espacio del contenedor */
    // background-repeat: no-repeat;
    // background-position: top left;
    padding: 0 !important;
    margin:  0 !important;
}

.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .alert-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    p{
        color: black !important
    }
  }
  
  .alert-button {
    padding: 10px 20px;
    background-color: $verde-lima;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

@media (max-width: 910px){
    .investigacion_card{
        width: 100%;
    }
}

@media (min-width: 910px) and (max-width: 1480px){
    .investigacion_card{
        padding-top: 0;
        h2{
            font-size: 25px;
            padding-bottom: 0 !important;
            margin-bottom: 10px !important;
        }
        p{
            font-size: 16px !important;
        }
    }
}